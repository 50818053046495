@media screen and (min-width: 480px) {
  body {
    background-color: lightgreen;
  }

  .logo-box {
    position:fixed;
  }
}
body{        
  padding-top: 0px;
  padding-bottom: 40px;
  background-color: rgba(61,205,40,0.26);
}

.fixed-header, .fixed-footer{
  width: 100%;
  position: fixed;        
  background: #2FC719;
  padding: 10px 0;
  color: #fff;
  z-index:5000;
}
.fixed-header{
  top: 0;
}
.fixed-footer{
  bottom: 0;
  text-align: center;  
}
.container{
  width: 80%;
  margin: 0 auto; /* Center the DIV horizontally */
}
.App {
  text-align: center;  
  justify-content: center;
  margin-top:50px;
}

.logo-box {
  position:fixed;
  top:0;
  margin:auto;
  height:50px;
  width:100%;
  background-image: linear-gradient(120deg, #2FC719 0%, #96F089 10%, #96F089 87%, #2FC719 100%);
  z-index:5000;
}

.fadeout {
  position:fixed;
  transform: translateY(-70px);
  z-index:5000;
  margin-top:50;
  height:30px;
  width:100%;
}

.fadeout1 {
  position:fixed;
  bottom:0;
  width: 100%;
  transform:translateY(-38px) rotate(180deg);
  z-index:5000;
  height:30px;
  width:100%;
}

.App-logo{
  display:block;
  margin:auto;
  width:242px;
  height:34px;
  transform: translate(0%, 25%);
 }

.card:first-of-type {
  margin-top:120px;
}

.card {
  width:300px;
  height:350px;
  margin:auto;
  margin-top:40px;
  margin-bottom:50px;
  top: 0%;
  bottom: 0%;
  background: #FFFFFF;
  border: 4px solid rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  z-index:10;
}
/* @keyframes yourAnimation{
  0%{
      transform: rotate(.3deg);
      }
  50%{
      transform: rotate(-.3deg);
      }
  100%{
      transform: rotate(.3deg);
      }
} */


/* @keyframes two {
  0% {
    transform: rotateY(0);
  }
  100% {
    transform: rotateY(7deg);
  }
}
@-moz-keyframes two {
  0% {
    transform: rotateY(0);
  }
  100% {
    transform: rotateY(7deg);
  }
}
@-webkit-keyframes two {
  0% {
    transform: rotateY(0);
  }
  100% {
    transform: rotateY(7deg);
  }
}
@-ms-keyframes two {
  0% {
    transform: rotateY(0);
  }
  100% {
    transform: rotateY(7deg);
  }
}
@-o-keyframes two {
  0% {
    transform: rotateY(0);
  }
  100% {
    transform: rotateY(7deg);
  }
} */

.event-img {
  border-radius:8px 8px 0 0;
  width:300px;
  height:186px;
 /*  animation: two .4s infinite;
  -webkit-transform-origin-z: 10;
  -moz-transform-origin-z: 10;
  -o-transform-origin-z: 10;
  -ms-transform-origin-z: 10;
 */ 
   /* animation: yourAnimation 1s infinite 0s linear; */

/*   -moz-animation: two .4s infinite;    
  -webkit-animation: two .4s infinite;
  -ms-animation: two .4s infinite;
  -o-animation: two .4s infinite;
  animation-direction: alternate;
  -moz-animation-direction: alternate;
  -webkit-animation-direction: alternate;
  -ms-animation-direction: alternate;
  -o-animation-direction: alternate;
 */  z-index:10;
}

.event-title {
  font-family: KoreanYS-B;
  font-size: 21px;
  color: #1E71FF;
  text-align: center;
  z-index:10;
}

.event-detail {
  font-family: KoreanHNDB-R;
  font-size: 17px;
  color: #FA6400;
  text-align: center;
  margin-bottom:10px;
  z-index:10;
}

.space {
  margin:30px;
  color:white;
}

/* @keyframes feature{
  0%{
      transform: translateX(100px);
      opacity: 0;
      }
  100%{
      transform: translateX(0);
      opacity: 1;
      }
} */


div.feature{
  width:284px;
  height:66px;
  border: red 1 solid ;
  position: relative;
  text-align: center;
  margin:auto;
  margin-top:40px;
  margin-bottom:40px;
/*   animation: feature 1s forwards .5s ease;
 */}

.box {
  height:200px;
}
.bar{
  width:283px;
  height:66px;
  margin:auto;

}

div.feature > div {
  position: absolute;
  top: 35%;
  left: 55%;
  transform: translate(-50%, -20%);
}

div.feature > div.line1 {
  position: absolute;
  width:283px;
  height:66px;
  top: 36%;
  left: 60%;
  transform: translate(-50%, -20%);
 }

div.feature > div.line2 {
  position: absolute;
  width:283px;
  height:66px;
  top: 72%;
  left: 60%;
  transform: translate(-50%, -20%);
}

div.feature > div.num{
  position: absolute;
  top: 45%;
  left: 12%;
  transform: translate(-50%, -50%);
  font-size:20px;
  font-family: Arial-BoldMT;
  font-size: 39px;
  color: #FFF;
  text-align: left;
}

.footer-box {
  position:fixed;
  top:0;
  margin:auto;
  margin-bottom:50px;
  height:50px;
  width:100%;
  background-image: linear-gradient(120deg, #2FC719 0%, #96F089 10%, #96F089 87%, #2FC719 100%);
  z-index:5000;
}

.reveal1 {
  opacity: 0;
  transform: scale(0.9);
  transition: all 1s ease-in-out;
}
.reveal1.visible {
  opacity: 1;
  transform: none;
}

.reveal1:nth-child(2) {
  margin-top: 40px;
}
.reveal1:nth-child(3) {
  margin-top: 40px;
}

.spacer.s2 {
  min-height: 900px;
}

.spacer {
  text-align: center;
  min-height: 100px;
/*   background-image: url(../img/example_bg_spacer_v.gif);
 */}

.spacer.s0 {
  min-height: 1px;
}

.digit {
  opacity: 0;
  animation: animated_div 5s 1;
/*   -webkit-transform: translateX(-40px);
     -moz-transform: translateX(-40px);
      -ms-transform: translateX(-40px);
       -o-transform: translateX(-40px);
          transform: translateX(-40px);
  -webkit-transition: all 0.6s ease-out;
     -moz-transition: all 0.6s ease-out;
      -ms-transition: all 0.6s ease-out;
       -o-transition: all 0.6s ease-out;
          transition: all 0.6s ease-out; */
}
.digit.visible {
  opacity: 1;
  transform: none;
}

@keyframes animated_div{
  0% {
    transform: rotate(0deg);
    left: 0px;
}
25% {
    transform: rotate(20deg);
    left: 0px;
}
50% {
    transform: rotate(0deg);
    left: 500px;
}
55% {
    transform: rotate(0deg);
    left: 500px;
}
70% {
    transform: rotate(0deg);
    left: 500px;
}
100% {
    transform: rotate(-360deg);
    left: 0px;
}
}